import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import { Helmet } from "react-helmet";
import CanvasJSReact from '@canvasjs/react-charts';
export class Summaries extends Component {
	static displayName = Summaries.name;
	constructor(props) {
		super(props);
		this.state = {
			summaryDataCount: [],
			summaryDataItems: [],
			summaryProgress: [],
			loading: true,
			itemsLoading: true,
			progressLoading: true,
			summaryDataComplex: [],
			complexloading: true,
			cntgenesMapped: 0,
			cntgenesUnidentified:0
		};
	}

	componentDidUpdate() {
		document.getElementById(window.location.hash.replace('#', ''))
			?.scrollIntoView({ behavior: 'instant', block: 'start' });
	}

	componentDidMount() {
		this.getGenesbyCategory();
		this.getmappingprogress();
	}

	static renderGeneLinks(genes) {
		return (
			<a className="tableLinkList" href={"/disease#" + genes.diseaseId} > { genes.symbol }</a>
		)
	}

	static renderTopandHome() {
		return (
			<p align="center">[ <a href="/summaries#top">Top of Page</a> | <a href="/">Home Page</a> ]</p>
		)
	}

	static renderListedTable(summary, title, tableId, colTitle1, ismapped, isIdentified, isComplex) {
		return (
			<div>
				{
					<table className="table table-bordered" aria-labelledby="tableLabel">
						<thead>
							<tr >
								<td colSpan="3" className="tableHeader" id={tableId}>
									<h5 id="a-genes">{title}</h5>
								</td>
							</tr>
							<tr align="center">
								<th width="40%">Disease Category</th>
								<th>{colTitle1}</th>
							</tr>
						</thead>
						<tbody>
							{
								summary.map(category => {
									return (
										<tr key={category.code}>

											<td >{category.description}</td>

											<td>												
												{
													category.genes.map(gene => {
														if (isComplex) {
															return (
																Summaries.renderGeneLinks(gene)
															)
														}
														else {
															if (gene.isMapped == ismapped && gene.isidentified == isIdentified) {
																return (
																	Summaries.renderGeneLinks(gene)
																)
															}
														}
													})
												}
											</td>
										</tr>
									)
								})
							}
						</tbody>
					</table>
				}
				{Summaries.renderTopandHome()}
			</div>
		);
	}

	toogleDataSeries(e) {
		if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
			e.dataSeries.visible = false;
		} else {
			e.dataSeries.visible = true;
		}
		e.chart.render();
	}

	static renderChart(chartData) {

		var mapDataPoints = [],
			identifiedDataPoints = [];

		chartData.forEach(function (p) {
			mapDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.mapped });
			identifiedDataPoints.push({ x: new Date(p.year, p.month - 1), y: p.identified });
		});

		var CanvasJSChart = CanvasJSReact.CanvasJSChart;

		var options = {
			animationEnabled: true,
			theme: "light1",
			zoomEnabled: true,
			zoomType: "x",
			fillOpacity: 100,
			title: {
				text: "Mapped and Identified Retinal Disease Genes",
				fontSize: 20,
				fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji",
			},
			axisY: {
				title: "",
				valueFormatString: "#0",
				includeZero: true,
				suffix: "",
				prefix: ""
			},
			legend: {
				cursor: "pointer",
				itemclick: this.toogleDataSeries,
				fontFamily: "system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Noto Sans, Liberation Sans, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji"
			},
			toolTip: {
				shared: true
			},
			data: [{
				type: "area",
				name: "Mapped",
				markerSize: 5,
				showInLegend: true,
				xValueFormatString: "MMMM-YYYY",
				yValueFormatString: "",
				dataPoints: mapDataPoints
			}, {
				type: "area",
				name: "Identified",
				markerSize: 5,
				showInLegend: true,
				yValueFormatString: "",
				dataPoints: identifiedDataPoints
			}]
		};
		
		return (

			<div className="w-80 element-center">
				<h5 id="d-graph" className="center">D. Graph</h5>
				<CanvasJSChart
					options={options}
					onRef={(ref) => (this.chart = ref)}
				/>
				<div className="f-inline-end margin-r-1">
					<a className="align-right" onClick={() => {
						this.chart.exportChart({ format: "jpg" });
					}} alt="Download">
						<i className="bi bi-download"></i> Download
					</a>
				</div>
				<span className="text-small text-italic">** Please ensure that your browser is in 100% zoom for better quality.</span><br/>
				<span className="text-small text-italic">** Highlight an area to zoom in.</span>
				
				<br />
			</div>
		)
	}

	render() {
		var standardCategory;
		var complexCategory;
		var unidentified;
		var cntgenesMapped = 0;
		var cntgenesUnidentified = 0;

		if (!this.state.itemsLoading) {
			standardCategory = this.state.summaryDataItems.genesByCategory.filter(item => item.isComplex == null).filter(item => item.genes.length > 0)
			unidentified = standardCategory.filter(item => item.genes.some(gene => gene.isMapped == 1 && gene.isidentified == 0));
			complexCategory = this.state.summaryDataItems.genesByCategory.filter(item => item.isComplex == 1).filter(item => item.genes.length > 0)

			cntgenesMapped = this.state.summaryDataItems.uniqueMappedandIdentifiedCount;
			cntgenesUnidentified = this.state.summaryDataItems.uniqueMappedandUnidentifiedCount;
		}

		let TableAContents = this.state.itemsLoading
			? <div className="loader section-center"></div>
			: Summaries.renderListedTable(standardCategory, 
				'Table A: Gene and Locus Symbols by Disease Category (one or more diseases per gene/locus)',
				'a-diseases',
				'Mapped and Identified Genes', 1, 1
			);

		let TableBContents = this.state.itemsLoading
			? <div className="loader section-center"></div>
			: Summaries.renderListedTable(unidentified,
				'Table B: Remaining Mapped Loci by Disease Category',
				'b-diseases',
				'Mapped Loci (gene not identified)',
				1, 0
			);

		let TableCContents = this.state.itemsLoading
			? <div className="loader section-center"></div>
			: Summaries.renderListedTable(complexCategory,
				'C. Genes Associated with Complex Forms of Retinal Disease',
				'c-complex',
				'Mapped and Named Loci',
				1, 0, true
			);

		let progress = this.state.progressLoading
			? <div className="loader section-center"></div> 
			: Summaries.renderChart(this.state.summaryProgress);

	  return (
			<div>
				<div className="App">
					<Helmet>
					  <title>RetNet: Summaries</title>
					</Helmet>
				</div>
			  <NavMenu />
		
			  <h3 align="center">By genes, loci or graph:</h3>
			  <p align="center">[<a href="summaries#a-genes"> A. Genes</a> | <a href="summaries#b-diseases">B. Loci</a> | <a href="summaries#c-complex">C. Complex Diseases</a> | <a href="summaries#d-graph">D. Graph</a>]</p>
			  <p align="center">Table A is a list of <i>all</i> genes associated with each disease, sometimes assigning several diseases per gene. Table B lists disease loci that have been mapped to a chromosomal location, but the underlying gene(s) have not yet been identified. Common diseases are listed in Table C but not in Tables A and B. Please see <a href="/faq">FAQ</a> for details.</p>
			  <hr />
			  <p align="center">Total number of genes to date:<b> {cntgenesMapped} </b></p>
			  <p align="center">Total number of genes + remaining unidentified loci: <b>{ cntgenesUnidentified}</b></p>
			  			 
			  <br/>
			  {TableAContents}
			  <br/>
			  {TableBContents}
			  <br />
			  {TableCContents}
			  <br />
			 {progress}
			  {Summaries.renderTopandHome()}
			</div>
		);
	}

	async populateSummariesData() {
		const response = await fetch('/api/summary', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataCount: data, loading: false });
		}
		else {
			alert(data.message);
		}

	}

	async populateSummariesDataByItem() {
		const response = await fetch('/api/summary/getitems', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataItems: data, itemsLoading: false });
		}
		else {
			alert(data.message);
		}
	}

	async getmappingprogress() {
		const response = await fetch('/api/summary/getmappingprogress', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryProgress: data, progressLoading: false });
		}
		else {
			alert(data.message);
		}
	}

	async populateSummariesDataByItemComplex() {
		const response = await fetch('/api/summary/getitems?iscomplex=true', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataComplex: data, complexloading: false });
		}
		else {
			alert(data.message);
		}
	}

	async getGenesbyCategory() {
		const response = await fetch('/api/summary/getgenesbycategory', {
			signal: AbortSignal.timeout(20000)
		});
		const data = await response.json();
		if (response.ok) {
			this.setState({ summaryDataItems: data, itemsLoading: false });
		}
		else {
			alert(data.message);
		}
	}	
}

