import React, { Component, useRef, Button } from 'react';
import { Helmet } from "react-helmet";
import { FormNav } from "./FormNav";
import { FormHeader } from "./FormHeader";
import { Input } from "./components/Input";
import { TextArea } from "./components/TextArea";
import { Modal } from "./components/Modal";
import { DropDown } from "./components/DropDown";
import { LookupModal } from "./components/LookupModal";
import JoditEditor, { Jodit } from 'jodit-react';
import context from '../../node_modules/react-bootstrap/esm/AccordionContext';
import $ from 'jquery';
import { ModalYesNo } from './components/ModalYesNo';
export class DiseasesForm extends Component {
	static displayName = DiseasesForm.name;
	constructor(props) {
		super(props);
		this.state = {
			diseaseitem: {},
			loading: true,
			references: {},
			refLoading: true,
			diseaseType: {},
			dTloading: true,
			isChecked: 0
		};
		DiseasesForm.submitHandler = DiseasesForm.submitHandler.bind(this);
		DiseasesForm.changeStatusHandler = DiseasesForm.changeStatusHandler.bind(this);
	}

	componentDidMount() {

		this.populateReferncesData();
		this.populateDiseaseTypeData();
		if (window.location.pathname === "/portal/disease/edit") {
			this.populateDiseasesData();
		}
		else {
			this.setState({ loading: false,  refLoading:false});
		}

	}


	static handleCheck = (e) => {
		if (e.target.checked) {
			this.setState({ isChecked: 1 })
		}
		else {
			this.setState({ isChecked: 1 })
		}
		
	}

	static displayData(disease)
	{
		if (window.location.pathname === "/portal/disease/edit") {
			if (disease) {
				for (var key in disease) {
					this.setformValue(disease, key);
				}
				var comment1 = document.getElementById("rt-comment1").getElementsByClassName("jodit-wysiwyg")[0];
				
				comment1.innerHTML =  disease.comment1
			}
		}
	}

	static setformValue(disease, field) {

		const inputfield = document.getElementById(field);
		
		if (inputfield !== null && disease[field] !== null) {
			inputfield.value = disease[field]
		}
	}

	static closeModal() {
		const modal = document.getElementById("modal");
		modal.style.display = "none";

	}

	static async submitHandler(status) {
	
		const form_ = document.getElementById("fm-disease");
		const submit = document.getElementById("form-submit");
		var isWithdrawn = document.getElementById("chk-withdrawn");

		const formData = new FormData(form_),
			disease = {};

		submit.blur();
		for (const [key, value] of formData) {

			if(value !== "")
			disease[key] = value;
		}
		var submitendpoint = ""
		if (window.location.pathname === "/portal/disease/new") {
			submitendpoint = "api/diseases/add";
		}
		else {
			disease.id = $("#id").text();
			submitendpoint = "api/diseases/update";
		}
		$('span[data-jodit-temp="true"]').remove();
		try {

			if (status) {
				disease.status = status;
			}

			disease.mappingMethod = DiseasesForm.getrichtextvalue("rt-mappingMethod");
			disease.disease1 = DiseasesForm.getrichtextvalue("rt-disease1");
			disease.disease2 = DiseasesForm.getrichtextvalue("rt-disease2");
			disease.comment1 = DiseasesForm.getrichtextvalue("rt-comment1");
			disease.comment2 = DiseasesForm.getrichtextvalue("rt-comment2");
			disease.comment3 = DiseasesForm.getrichtextvalue("rt-comment3");
			
			if (isWithdrawn.checked) {
				disease.withdrawn = 1;
			}
			else {
				disease.withdrawn = 0;
			}

			const response = await fetch(submitendpoint, {
				method: "POST",
				credentials: "include",
				body: JSON.stringify(disease),
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();

			Modal.modalDisplay(response.ok, data.message);
			
		}
		catch(err) {
			Modal.modalDisplay(false, err);
		}
	}

	static ConfirmChangeStatus(show, question, id, param2) {
		ModalYesNo.modalDisplay(show, question, id, param2);
	}

	static async changeStatusHandler(id, status) {
		const  disease = {};

		disease.id = $("#id").text();
		disease.status = status;

		const submitendpoint = "api/diseases/changestatus";

		const response = await fetch(submitendpoint, {
			method: "POST",
			credentials: "include",
			body: JSON.stringify(disease),
			headers: {
				"content-type": "Application/json",
				"Accept": "application/json"
			}
		});
		const data = await response.json();

		Modal.modalDisplay(response.ok, data.message);
	}

	static getrichtextvalue(id) {

		var editor = $('#' + id).find(".jodit-wysiwyg")[0];
		
		this.removeLastBr(editor);
		
		if (editor) {
			var editor_val = editor.innerHTML;
			editor_val.replace(/^\uFEFF/gm, "");
			editor_val.replace(/[^\x20-\x7E]/g, '')
			if (editor.innerHTML.trim().length == 0) {
				return ""
			}

			if (editor_val.includes('<html>') || editor_val.includes('<body>') || editor_val.includes('<script>')) {
				throw new Error("Content for " + id + " contains unwanted elements that may cause rendering issues. Please review your entry.")
			}

			return editor_val;
		}
	}

	static removeLastBr(editor) {
		var br = $(editor).find('br:last-child')[0];
		if (br) {
			var nextSib = br.nextSibling;

			if (!nextSib) {
				br.remove();
				this.removeLastBr(editor);
			}
			
		}
	}

	static renderForm(disease, references, diseasetype) {

		const buttons = [
			"undo",
			"redo",
			"|",
			"bold",
			"strikethrough",
			"underline",
			"italic",
			"|",
			"superscript",
			"subscript",
			"|",
			"|",
			"link"
		];

		const editorConfig = {
			enter: 'br',
			readonly: false,
			toolbar: true,
			spellcheck: true,
			language: "en",
			toolbarButtonSize: "medium",
			toolbarAdaptive: false,
			showCharsCounter: true,
			showWordsCounter: true,
			showXPathInStatusbar: false,
			askBeforePasteHTML: true,
			askBeforePasteFromWord: true,
			defaultActionOnPaste: "insert_only_text",
			buttons: buttons,
			cleanHTML: {
				fillEmptyParagraph: false
			},
			uploader: {
				insertImageAsBase64URI: false
			},
			pasteHTMLActionList: Jodit.atom([
				{ value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' }
			]),
			width: 800,
			height: 800,
			addNewLineOnDBLClick: false,
			nl2brInPlainText: false,
			addNewLine : false
		};

		var pageTitle = "",
			isNew = false,
			callbackurl = "";
		if (window.location.pathname === "/portal/disease/new")
		{
			pageTitle = "New Disease";
			isNew = true;
			callbackurl = "/portal/disease/";

		}
		if (window.location.pathname === "/portal/disease/edit") {
			pageTitle = "Edit Disease";
			isNew = false
			callbackurl = "/portal/disease/edit?id=" + disease.id;
		}

		return (
			<div className="form-container">

				<Helmet>
					<title>RetNet Portal: {pageTitle} </title>
				</Helmet>

				<ModalYesNo title={pageTitle}
					parentCallback={this.changeStatusHandler} />

				<Modal title={pageTitle} okurl={callbackurl} failurl={callbackurl} />
				<FormHeader />
				<FormNav />

				<div className="form-content box-shadow bg-white-smoke border-gray border-round">
					<h3 className="position-absolute pad-10p">{pageTitle}</h3>

					<form id="fm-disease" action="#" className='entryform'>



						<div className="btn-group">
							<a href="/portal/disease"><i className="bi bi-x-lg"></i></a>
						</div>
						&nbsp;
						{
							(!isNew) ?
								(disease.status == 1) ? 
									<div className="statusIndicator bg-yellow"></div> :
								(disease.status == 2) ?
									<div className="statusIndicator bg-green"></div> :
										<div className="statusIndicator bg-red"></div>
									: <hr/>
						}

						<p id="id" className="display-none">{ disease.id}</p>
						<div className="grid-container">
							<div className="grid-item fields-padding">
								<DropDown isChromosomes={true} caption="--" name="chromosome" label="Chromosome" cssclass="w-25" required={true} value={(pageTitle === "Edit Disease") ? disease.chromosomeNo : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Sort Number" name="sortNo" type="Text" cssclass="w-25" required={true} value={(pageTitle === "Edit Disease") ? disease.sortNo : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Location" name="location" type="Text" cssclass="w-25" required={true} value={(pageTitle === "Edit Disease") ? disease.location : ""} />
							</div>
							<div className="grid-item fields-padding">
								<DropDown isMonths={true} caption="--" name="newMonth" label="Month" cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.newMonth : ""} />
							</div>
							<div className="grid-item fields-padding">
								<DropDown isYears={true} caption="--" name="newYear" label="Year" cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.newYear : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Genome Site" name="genomeSite" type="Text" cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.genomeSite : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Symbol 1" name="symbol1" type="Text" cssclass="w-50" value={(pageTitle === "Edit Disease") ? disease.symbol1 : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="ClinGen ID" name="clinGen" type="Text" cssclass="w-50" value={(pageTitle === "Edit Disease") ? disease.clinGen : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Symbol 2" name="symbol2" type="Text" cssclass="w-50" value={(pageTitle === "Edit Disease") ? disease.symbol2 : ""} />
							</div>
							
							<div className="grid-item fields-padding">
								<Input label="Protein" name="protein" type="Text" cssclass="w-50" value={(pageTitle === "Edit Disease") ? disease.protein : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Locus Link (Gene)" name="locusLink" type="Text" cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.locusLink : ""} />
							</div>
							<div className="grid-item fields-padding">
								<Input label="Protein Symbol" name="proteinSymbol" type="Text" cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.proteinSymbol : ""} />
							</div>
							
						</div>
						<div className="w-50 fields-padding f-left">
							<Input label="McKusick" name="mcKusick" type="Text" cssclass="w-50" value={(pageTitle === "Edit Disease") ? disease.mcKusick : ""} />
						</div>	
						<div className="w-50 pad-10p  fields-padding  f-left">
							<LookupModal selections={references}
								label="Reference" required={true}
								title="References"
								value={(pageTitle === "Edit Disease") ? disease.reference : ""}
								name="reference"
								descriptioncaption="Title" />
						</div>
						<div className="pad-10p w-50 f-left fields-padding">
							<DropDown caption="--" name="diseaseType1" label="Disease Type 1" options={diseasetype} cssclass="w-25" value={(pageTitle === "Edit Disease") ? disease.diseaseType1 : ""} />
						</div>

						<div className="w-50 pad-10p f-left  fields-padding">
							<LookupModal selections={diseasetype}
								label="Disease Type 2"
								title="Disease Type 2"
								value={(pageTitle === "Edit Disease") ? disease.diseaseType2 : ""}
								name="diseaseType2"
								descriptioncaption="Disease Type" />
						</div>


						<br/>
						<div className="w-100 pad-10p " id="rt-mappingMethod">
							<label className="form-label">Mapping Method <span className="text-red">*</span></label>
							<JoditEditor config={editorConfig} value={disease.mappingMethod} tabIndex={1} id="rt-mappingMethod-jodit" />
						</div>
						
						<div className="w-100 pad-10p " id="rt-disease1">
							<label className="form-label">Disease 1 <span className="text-red">*</span></label>
							<JoditEditor config={editorConfig} value={disease.disease1} tabIndex={2} id="rt-disease1-jodit" />
						</div>

						<div className="w-100 pad-10p " id="rt-disease2">
							<label className="form-label">Disease 2</label>
							<JoditEditor config={editorConfig} value={disease.disease2} tabIndex={3} id="rt-disease2-jodit" />
						</div>
						<div className="w-100 pad-10p " id="rt-comment1">
							<label className="form-label">Comment 1</label>
							<JoditEditor config={editorConfig} value={disease.comment1} tabIndex={4} id="rt-comment1-jodit" />
						</div>
						<div className="w-100 pad-10p " id="rt-comment2">
							<label className="form-label">Comment 2</label>
							<JoditEditor config={editorConfig} value={disease.comment2} tabIndex={5} id="rt-comment2-jodit" />
						</div>
						<div className="w-100 pad-10p " id="rt-comment3">
							<label className="form-label">Comment 3</label>
							<JoditEditor config={editorConfig} value={disease.comment3} tabIndex={6} id="rt-comment3-jodit" />
						</div>

						<div className="pad-10p">
							<input className="form-check-input" type="checkbox" id="chk-withdrawn" defaultChecked={disease.withdrawn} name="withdrawn" /> 
							<label for="chk-include-count" className="form-label">Withdrawn</label>
						</div>

						<div className="pad-1 f-inline-end">
							{
								(disease.status === 1 || disease.status === 2 || isNew) ?
									<a className={isNew ? "btn btn-primary margin-5" : "btn btn-primary"} id="form-submit" onClick={() => this.submitHandler()}>Save</a> :
									<a className="btn btn-primary margin-5" id="form-submit" onClick={e => { e.preventDefault(); this.submitHandler(1) }}>Save as Draft</a>
							}
							{ 
								(disease.status === 1 ) ?
									<a className="btn btn-primary margin-5" id="form-submit" onClick={e => { e.preventDefault(); this.submitHandler(2) }}>Save and Publish</a> :
									""		
							}
							{
								(disease.status === 2) ?
									<a className="btn btn-primary margin-5" id="form-submit" onClick={e => { e.preventDefault(); this.ConfirmChangeStatus(true, "Changes to this record will not be saved. <br/>Do you wish to continue?", disease.id, 1) }}>Unpublish</a> :
									""
							}
							<a className='btn-red register btn btn-secondary' href="/portal/disease">Cancel</a>
						</div>
						
					</form>
					
					<p>&nbsp;</p>
					<br />
				</div>
			</div>
		);

	}


	render() {
		
		let contents = this.state.loading && this.state.refLoading && this.state.dTloading
			? ""
			: DiseasesForm.renderForm(this.state.diseaseitem, this.state.references, this.state.diseaseType)

		return (<div>
			{contents}
		</div>);
	}

	async populateDiseasesData() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		try {

			const response = await fetch("api/diseases/getitem?id=" + urlParams.get('id'), {

				credentials: "include",
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();
			if (response.ok) {
				this.setState({ diseaseitem: data, loading: false });
			}
			else {
				window.location.href = "/portal";
			}
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}

	async populateReferncesData() {
		try {
			const response = await fetch('/api/references/getreferences', {
				signal: AbortSignal.timeout(20000),
				credentials: "include",
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();
			if (response.ok) {
				this.setState({ references: data, refLoading: false });
			}
			else {
				window.location.href = "/portal";
			}
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}

	async populateDiseaseTypeData() {
		try {
			const response = await fetch('/api/diseasetype', {
				signal: AbortSignal.timeout(20000),
				credentials: "include",
				headers: {
					"content-type": "Application/json",
					"Accept": "application/json"
				}
			});
			const data = await response.json();
			if (response.ok) {
				this.setState({ diseaseType: data, dTloading: false });
			}
			else {
				window.location.href = "/portal";
			}
		}
		catch (err) {
			window.location.href = "/portal";
		}
	}
}

